@media screen and (max-width: 991px) {
  .first-block {
    display: block;

    &__image {
      margin-right: 0;
      margin-bottom: 20px;
      text-align: center;
    }
  }
}
@media screen and (max-width: 768px) {
  body {
    background-color: #fff;
    background-image: none;
    color: #000;
  }

  header {
    &.header-wrap {
      padding: 5px 15px;
      background-color: #3A3A3A;
    }

    .header {
      &__burger {
        display: block;

        &.active {
          span {
            &:nth-child(1) {
              transform: rotate(23deg);
              transform-origin: left;
            }
            &:nth-child(2) {
              width: 0;
            }
            &:nth-child(3) {
              transform: rotate(-23deg);
              transform-origin: left;
            }
          }
        }
      }

      &__logo {
        background-color: transparent;
        color: #fff;
        padding: 0;
      }

      &__nav {
        position: absolute;
        top: 0;
        right: -100%;
        padding: 125px 20px 70px 20px;
        width: 50%;
        height: 100%;
        color: #fff;
        background-image: linear-gradient(180deg, #374151 0%, #111827 100%);
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;

        &.active {
          right: 0;
          opacity: 1;
          visibility: visible;
        }
        ul {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          li {
            width: 100%;
            padding-bottom: 10px;
            margin: 0 0 20px 0;
            border-bottom: 1px solid #fff;

            &:before {
              display: none;
            }
          }
        }
      }
      &__search {
        display: none;
      }
    }
  }

  .first-block {
    &__text {
      p {
        color: #000;
      }
    }
  }

  .content {
    blockquote {
      max-width: 100%;
      padding: 25px;
      background-color: #ccc;

      &:before {
        display: none;
      }
    }
    table {
      max-width: 100%;

      td {
        border-color: #000;
      }
    }
  }

  footer {
    &.footer-wrap {
      background-color: #3A3A3A;
      padding: 10px 15px;
    }
    .copyright {
      font-size: 12px;
      color: #fff;
    }
  }
}

@media screen and (max-width: 480px) {
  header {
    .header {
      &__nav {
        width: 100%;
      }
    }
  }
  .content {
    img {
      &.left {
        float: none;
        margin-right: 0;
        width: 100%;
      }
      &.right {
        float: none;
        margin-left: 0;
        width: 100%;
      }
    }
    ul, ol {
      padding-left: 0;
    }
  }

  //.news {
  //  &__item {
  //    display: block;
  //  }
  //}
}