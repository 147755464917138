@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunitoregular.woff2") format("woff2"),
      url("../fonts/Nunitoregular.woff") format("woff"),
      url("../fonts/Nunitoregular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunitobold.woff2") format("woff2"),
      url("../fonts/Nunitobold.woff") format("woff"),
      url("../fonts/Nunitobold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}