@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunitoregular.woff2") format("woff2"), url("../fonts/Nunitoregular.woff") format("woff"), url("../fonts/Nunitoregular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunitobold.woff2") format("woff2"), url("../fonts/Nunitobold.woff") format("woff"), url("../fonts/Nunitobold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  position: relative;
  overflow-x: hidden;
}
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  font-family: Nunito, Arial, sans-serif;
  line-height: 1.4;
  color: #fff;
  background-image: linear-gradient(180deg, #374151 0%, #111827 100%);
  background-color: #374151;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
ol,
ul {
  list-style-position: inside;
}
.wrap {
  width: 100%;
  max-width: 1130px;
  margin: 0 auto;
  padding: 0 15px;
}
header.header-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 70px 0 50px;
  margin-bottom: 25px;
}
header .header__burger {
  display: none;
  position: relative;
  width: 30px;
  height: 15px;
}
header .header__burger span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #fff;
  transition: all 0.3s ease;
}
header .header__burger span:nth-child(2) {
  top: 6px;
}
header .header__burger span:nth-child(3) {
  top: 12px;
}
header .header__logo {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #4B5563;
  padding: 6px 30px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}
header .header__nav {
  font-weight: 600;
  font-size: 24px;
}
header .header__nav ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
header .header__nav ul li {
  position: relative;
  margin: 0 12px;
}
header .header__nav ul li:before {
  display: block;
  content: '';
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
header .header__nav ul li.current:before,
header .header__nav ul li:hover:before {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}
header .header__search {
  position: relative;
}
header .header__search input {
  width: 100%;
  max-width: 220px;
  font-size: 16px;
  background-color: rgba(31, 41, 55, 0.6);
  padding: 10px 15px 10px 50px;
  border: none;
  outline: none;
  border-radius: 20px;
  color: #fff;
}
header .header__search:before {
  position: absolute;
  display: block;
  content: '';
  width: 14px;
  height: 14px;
  left: 18px;
  top: 12px;
  background-image: url('../img/icon-search.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 1;
}
.first-block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 70px;
}
.first-block__image {
  flex-basis: 65%;
  height: 390px;
  margin-right: 30px;
  border-radius: 10px;
  overflow: hidden;
}
.first-block__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.first-block__text {
  flex: 1;
}
.first-block__text h1 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}
.first-block__text p {
  color: rgba(255, 255, 255, 0.6);
}
.news {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -15px 20px;
}
.news__item {
  max-width: 340px;
  margin: 0 15px 30px;
}
.news__item:hover .news__img {
  opacity: 0.7;
}
.news__item:hover .news__title {
  text-decoration: underline;
}
.news__img {
  display: block;
  width: 100%;
  height: 200px;
  margin-bottom: 50px;
  opacity: 1;
  transition: all 0.3s ease;
  border-radius: 10px;
  overflow: hidden;
}
.news__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.news__title {
  font-weight: 700;
}
.content {
  width: 100%;
  max-width: 950px;
  margin: 0 auto 30px;
}
.content > * {
  margin-bottom: 20px;
}
.content h1,
.content h2,
.content h3 {
  font-weight: 700;
  text-align: center;
}
.content h1 {
  font-size: 36px;
  margin-bottom: 35px;
}
.content h2 {
  font-size: 28px;
}
.content h3 {
  font-size: 20px;
}
.content p {
  text-indent: 15px;
}
.content img {
  border-radius: 30px;
}
.content img.full {
  width: 100%;
}
.content img.left {
  float: left;
  margin-right: 15px;
}
.content img.right {
  float: right;
  margin-left: 15px;
}
.content img.center {
  display: block;
  max-width: 100%;
  margin: 0 auto 12px;
}
.content blockquote {
  position: relative;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(83, 81, 100, 0.52);
  border-radius: 20px;
  padding: 25px 100px 25px 150px;
  font-style: italic;
}
.content blockquote:before {
  display: block;
  content: '';
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  width: 70px;
  height: 70px;
  background-image: url('../img/icon-quote.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.content table {
  width: 100%;
  max-width: 90%;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
}
.content table td {
  padding: 10px 5px;
  border-bottom: 1px solid #fff;
  font-weight: 700;
  text-align: center;
}
footer.footer-wrap {
  width: 100%;
  padding: 20px 0 40px;
}
footer .copyright {
  font-size: 16px;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}
@media screen and (max-width: 991px) {
  .first-block {
    display: block;
  }
  .first-block__image {
    margin-right: 0;
    margin-bottom: 20px;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  body {
    background-color: #fff;
    background-image: none;
    color: #000;
  }
  header.header-wrap {
    padding: 5px 15px;
    background-color: #3A3A3A;
  }
  header .header__burger {
    display: block;
  }
  header .header__burger.active span:nth-child(1) {
    transform: rotate(23deg);
    transform-origin: left;
  }
  header .header__burger.active span:nth-child(2) {
    width: 0;
  }
  header .header__burger.active span:nth-child(3) {
    transform: rotate(-23deg);
    transform-origin: left;
  }
  header .header__logo {
    background-color: transparent;
    color: #fff;
    padding: 0;
  }
  header .header__nav {
    position: absolute;
    top: 0;
    right: -100%;
    padding: 125px 20px 70px 20px;
    width: 50%;
    height: 100%;
    color: #fff;
    background-image: linear-gradient(180deg, #374151 0%, #111827 100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
  header .header__nav.active {
    right: 0;
    opacity: 1;
    visibility: visible;
  }
  header .header__nav ul {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  header .header__nav ul li {
    width: 100%;
    padding-bottom: 10px;
    margin: 0 0 20px 0;
    border-bottom: 1px solid #fff;
  }
  header .header__nav ul li:before {
    display: none;
  }
  header .header__search {
    display: none;
  }
  .first-block__text p {
    color: #000;
  }
  .content blockquote {
    max-width: 100%;
    padding: 25px;
    background-color: #ccc;
  }
  .content blockquote:before {
    display: none;
  }
  .content table {
    max-width: 100%;
  }
  .content table td {
    border-color: #000;
  }
  footer.footer-wrap {
    background-color: #3A3A3A;
    padding: 10px 15px;
  }
  footer .copyright {
    font-size: 12px;
    color: #fff;
  }
}
@media screen and (max-width: 480px) {
  header .header__nav {
    width: 100%;
  }
  .content img.left {
    float: none;
    margin-right: 0;
    width: 100%;
  }
  .content img.right {
    float: none;
    margin-left: 0;
    width: 100%;
  }
  .content ul,
  .content ol {
    padding-left: 0;
  }
}
