@import "fonts";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  position: relative;
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  font-family: Nunito, Arial, sans-serif;
  line-height: 1.4;
  color: #fff;
  background-image: linear-gradient(180deg, #374151 0%, #111827 100%);
  background-color: #374151;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
ol, ul {
  list-style-position: inside;
}

.wrap {
  width: 100%;
  max-width: 1130px;
  margin: 0 auto;
  padding: 0 15px;
}

header {
  &.header-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 70px 0 50px;
    margin-bottom: 25px;
  }
  .header {
    &__burger {
      display: none;
      position: relative;
      width: 30px;
      height: 15px;

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #fff;
        transition: all .3s ease;

        &:nth-child(2) {
          top: 6px;
        }
        &:nth-child(3) {
          top: 12px;
        }
      }
    }
    &__logo {
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      background-color: #4B5563;
      padding: 6px 30px;
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
    }
    &__nav {
      font-weight: 600;
      font-size: 24px;

      ul {
        list-style: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        li {
          position: relative;
          margin: 0 12px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            bottom: 4px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #fff;
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease;
          }
          &.current,
          &:hover {
            &:before {
              opacity: 1;
              visibility: visible;
              bottom: 0;
            }
          }
        }
      }
    }
    &__search {
      position: relative;

      input {
        width: 100%;
        max-width: 220px;
        font-size: 16px;
        background-color: rgba(31, 41, 55, 0.6);
        padding: 10px 15px 10px 50px;
        border: none;
        outline: none;
        border-radius: 20px;
        color: #fff;
      }
      &:before {
        position: absolute;
        display: block;
        content: '';
        width: 14px;
        height: 14px;
        left: 18px;
        top: 12px;
        background-image: url('../img/icon-search.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        z-index: 1;
      }
    }
  }
}

.first-block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 70px;

  &__image {
    flex-basis: 65%; 
    height: 390px;
    margin-right: 30px;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__text {
    flex: 1;

    h1 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    p {
      color: rgba(255, 255, 255, 0.6);
    }
  }
}

.news {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -15px 20px;

  &__item {
    max-width: 340px;
    margin: 0 15px 30px;

    &:hover {
      .news {
        &__img {
          opacity: 0.7;
        }
        &__title {
          text-decoration: underline;
        }
      }
    }
  }
  &__img {
    display: block;
    width: 100%;
    height: 200px;
    margin-bottom: 50px;
    opacity: 1;
    transition: all .3s ease;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }
  &__title {
    font-weight: 700;
  }
}

.content {
  width: 100%;
  max-width: 950px;
  margin: 0 auto 30px;

  & > * {
    margin-bottom: 20px;
  }

  h1, h2, h3 {
    font-weight: 700;
    text-align: center;
  }
  h1 {
    font-size: 36px;
    margin-bottom: 35px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 20px;
  }
  p {
    text-indent: 15px;
  }
  img {
    border-radius: 30px;

    &.full {
      width: 100%;
    }
    &.left {
      float: left;
      margin-right: 15px;
    }
    &.right {
      float: right;
      margin-left: 15px;
    }
    &.center {
      display: block;
      max-width: 100%;
      margin: 0 auto 12px;
    }
  }
  blockquote {
    position: relative;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(83, 81, 100, 0.52);
    border-radius: 20px;
    padding: 25px 100px 25px 150px;
    font-style: italic;

    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
      width: 70px;
      height: 70px;
      background-image: url('../img/icon-quote.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  table {
    width: 100%;
    max-width: 90%;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;

    td {
      padding: 10px 5px;
      border-bottom: 1px solid #fff;
      font-weight: 700;
      text-align: center;
    }
  }
}

footer {
  &.footer-wrap {
    width: 100%;
    padding: 20px 0 40px;
  }
  .copyright {
    font-size: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
  }
}

@import "media";